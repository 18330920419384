import React from 'react';
import Image1 from './images/Image1.jpg'
import Image2 from './images/Image2.jpg'
import Image3 from './images/Image3.jpg'
import Image4 from './images/Image4.jpg'
import Image5 from './images/Image5.jpg'
import Image6 from './images/Image6.jpg'
import Image7 from './images/Image7.jpg'
import Image8 from './images/Image8.jpg'
import Image9 from './images/Image9.jpg'
import Image10 from './images/Image10.jpg'
import ThreePeaks1 from './images/ThreePeaks1.jpg'
import ThreePeaks2 from './images/ThreePeaks2.jpg'
import SunnyView1 from './images/SunnyView1.jpg'
import SunnyView2 from './images/SunnyView2.jpg'
import { 
  Container, 
  Grid,
  Typography
 } from '@mui/material';
import ImageStepper from './components/ImageStepper.js'
import Banner from './components/Banner';
import PopularFacilities from './components/PopularFacilities';
import ImageCard from './components/ImageCard';
import ContactUs from './components/ContactUs';

const bannerData = {
  title: 'Alma Cliffe Guest House',
  location: "Manchester Road, Barnoldswick, BB18 5QT, United Kingdom",
  description: [
    "The property is in an elevated position which gives way to wonderful views of the Ribble Valley. There are 31 steps up to the property and parking is free of charge but down on the road at the front of the house.",
    "A continental breakfast is included in the room rate which includes toast, pastries, cereals, fruit and yoghurt. A cooked breakfast is available a small English breakfast is £5.50 and a large at £7.50. A gluten free breakfast is available on request also a vegetarian or vegan option is available.",
    "We have a guest lounge/dining room were you can sit comfortably and watch TV or just chill and relax. My property is dog friendly and all well behaved house trained dogs are very welcome. Dogs are not permitted on beds or furniture and are not to be left alone"
  ],
  image: Image1,
}

const threePeaksData = {
  title: "The Three Peaks",
  subTitle: "Double Room with Mountain View",
  description: "This room is a double ensuite and has a wonderful panoramic of the ribble valley and The Three Peaks. Tea and coffee making facilities are available in the guest dining room and a few light refreshments are on offer.",
  image: ThreePeaks1
}

const sunnyViewData = {
  title: "Sunny View",
  subTitle: "Family Room",
  description: "This is our family room with a private bathroom including a bath. A king size bed and a single bed are in this room with possibilities for another single if need be. Tea and coffee making facilities are available in the guest lounge.",
  image: SunnyView1
}

const images = [
  {
    imgPath: Image1
  },
  {
    imgPath: Image2
  },
  {
    imgPath: Image3
  },
  {
    imgPath: Image4
  },
  {
    imgPath: Image5
  },
  {
    imgPath: Image6
  },
  {
    imgPath: Image7
  },
  {
    imgPath: Image8
  },
  {
    imgPath: Image9
  },
  {
    imgPath: Image10
  },
  {
    imgPath: ThreePeaks1
  },
  {
    imgPath: ThreePeaks2
  },
  {
    imgPath: SunnyView1
  },
  {
    imgPath: SunnyView2
  }
];

const Home = () => {
  
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} marginTop={1} display={"flex"}>
        <Grid item xs={12}>
          <Banner data={bannerData} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' sx={{display:{xs:'block',md:'none'}}}>
            About Us
          </Typography>
          <Typography variant='body1' sx={{display:{xs:'block',md:'none'}}}>
            The property is in an elevated position which gives way to wonderful views of the Ribble Valley. There are 31 steps up to the property and parking is free of charge but down on the road at the front of the house.
            <br /><br />
            A continental breakfast is included in the room rate which includes toast, pastries, cereals, fruit and yoghurt. A cooked breakfast is available a small English breakfast is £5.50 and a large at £7.50. A gluten free breakfast is available on request also a vegetarian or vegan option is available.
            <br /><br />
            We have a guest lounge/dining room were you can sit comfortably and watch TV or just chill and relax. My property is dog friendly and all well behaved house trained dogs are very welcome. Dogs are not permitted on beds or furniture and are not to be left alone.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PopularFacilities />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ImageCard data={threePeaksData}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ImageCard data={sunnyViewData}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>
            Barnoldswick
          </Typography>
          <Typography variant='body1'>
            Our town is full of character and characters. You will find some nice shops to browse around and some wonderful restaurants and public houses. We have the Barnoldswick music centre just a ten minute walk away which has some amazing bands playing all year round. 
            <br /><br />
            Our property sits on the outskirts of the Yorkshire Dales, we are very near to Skipton and Clitheroe. The Leeds Liverpool canal is ten minutes away and we are also on the route of the pennine way. This is a perfect base for walkers and cyclists, we can lock up to four bikes in our shed for security. 
          </Typography>
        </Grid>
        <Grid item md={12} lg={6}>
          <ImageStepper images={images}/>
        </Grid>
        <Grid item md={12} lg={6}>
          <ContactUs />
        </Grid>
      </Grid>
    </Container>  
  );
}

export default Home;