import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FacebookIcon from '@mui/icons-material/Facebook';

const ContactUs = () => {
  return (
    <Box>
      <Typography variant="h5" color="black" gutterBottom> Contact Us </Typography>
      <Stack direction="row" alignItems="center" gap={1} color={"text.secondary"}>
        <EmailIcon/>
        <Typography color={"text.secondary"} variant="h6" sx={{display:{xs:'none',sm:'flex'}}}>Email:</Typography>
        <Typography color={"text.primary"} variant="h6">almacliffe.uk@gmail.com</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} color={"text.secondary"}>
        <LocalPhoneIcon/>
        <Typography color={"text.secondary"} variant="h6" sx={{display:{xs:'none',sm:'flex'}}}>Phone:</Typography>
        <Typography color={"text.primary"} variant="h6">&#40;+44&#41; 07793074690</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} color={"text.secondary"}>
        <FacebookIcon/>
        <Typography color={"text.secondary"} variant="h6" sx={{display:{xs:'none',sm:'flex'}}}>Facebook:</Typography>
        <Typography color={"text.primary"} variant="h6">Alma Cliffe Guest House</Typography>
      </Stack>
    </Box>
  );
}

export default ContactUs;