import React from 'react';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import WifiIcon from '@mui/icons-material/Wifi';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import PetsIcon from '@mui/icons-material/Pets';
import { 
  Typography,
  Stack,
  Box,
  Grid
 } from '@mui/material';

const PopularFacilities = (props) => {
    const { post } = props;
    return (
      <Box>
        <Grid container spacing={3} p={1}>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <LocalParkingIcon/>
              <Typography color={"text.primary"}>Free Parking</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <SmokeFreeIcon/>
              <Typography color={"text.primary"}>Non-Smoking Rooms</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <WifiIcon/>
              <Typography color={"text.primary"}>Fast Free WiFi &#40;102 Mbps&#41;</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <FamilyRestroomIcon/>
              <Typography color={"text.primary"}>Family Rooms</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <CoffeeMakerIcon/>
              <Typography color={"text.primary"}>Tea/Coffee Available</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <FreeBreakfastIcon/>
              <Typography color={"text.primary"}>Superb Breakfast</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={1} color={"green"}>
              <PetsIcon/>
              <Typography color={"text.primary"}>Dog Friendly</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    )
  }
  
  export default PopularFacilities;