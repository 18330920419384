import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PlaceIcon from '@mui/icons-material/Place';

const Banner = (props) => {
  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${props.data.image})`,
        aspectRatio: "5/2"
      }}
    >
      {<img style={{ display: 'none' }} src={props.data.image} alt={props.data.imageText} />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.4)',
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography component="h1" variant="h3" color="inherit" gutterBottom align={"center"}>
              {props.data.title}
            </Typography>
            <Stack direction="row" gap={1} color="inherit" justifyContent={"center"} >
              <PlaceIcon/>
              <Typography variant="h5" gutterBottom>
                {props.data.location}
              </Typography>
            </Stack>
            <Box sx={{ display: { md: 'block', xs: 'none' }, mr: 1 }} justifyContent={"bottom"} width={"95%"} marginTop={20}>
              <Typography variant='h5'> About Us </Typography>
              {props.data.description.map((desc, index) => {
                return (
                  <Typography variant="body1" color="inherit" paragraph key={index}>
                    {desc}
                  </Typography>
                )})}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Banner;