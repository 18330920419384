import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const ImageCard = (props) => {
  return (
    <Card sx={{ display: 'flex', height: "100%" }}>
      <CardContent sx={{ flex: 1 }}>
      <Typography component="h2" variant="h5">
          {props.data.title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
          {props.data.subTitle}
      </Typography>
      <Typography variant="subtitle1" paragraph>
          {props.data.description}
      </Typography>
      </CardContent>
      <CardMedia
      component="img"
      sx={{ width: {xs: 160, md: 240}, display: { xs: 'none', sm: 'block' } }}
      image={props.data.image}
      alt={props.data.title}
      />
    </Card>
  );
}

export default ImageCard;